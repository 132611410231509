<template>
  <v-dialog v-model="dialog"  persistent :overlay="false" max-width="800px" transition="dialog-transition">
    <v-snackbar v-model="snackErreur" color="red">
      {{snackErreurMessage}}
      <v-btn text color="white" @click.native="snackErreur = false">fermer</v-btn>
    </v-snackbar>

    <v-card>

      <v-card-title primary-title v-if="!creation">
        Modification container {{aContainer.ctContainer}}
      </v-card-title>

      <v-card-title primary-title v-if="creation">
        Création d'un container
      </v-card-title>

      <v-card-text>
        <v-form v-model="valid">
          <h-group style="align-items: start;">
            
            <v-group class="pa-5 mr-4" style="background-color: #F0F0F0;  width: 400px; align-items: stretch">
              <v-text-field name="ctContainer" :rules="nameRules" required label="Numéro unique de container" autofocus v-model="aContainer.ctContainer" v-if="creation"></v-text-field>  
              <v-text-field name="ctScac" label="Scac" v-model="aContainer.ctScac"></v-text-field>  
              <v-text-field name="ctStatut" label="Statut" v-model="aContainer.ctStatut"></v-text-field>  
              <v-text-field name="ctType" label="Type" v-model="aContainer.ctType"></v-text-field>  
              <v-text-field name="ctPoids" label="Poids (kg)" v-model="aContainer.ctPoids"></v-text-field>  
              <v-text-field name="ctTare" label="Tare (kg)" v-model="aContainer.ctTare"></v-text-field>
            </v-group>
            
            <v-group class="pa-5 mr-4" style="background-color: #F0F0F0; width: 400px;align-items: stretch">
              <v-checkbox label="Présent" v-model="present"></v-checkbox>
              <v-text-field name="ctDateArrival" type="date" label="Date arrivée" v-model="dateArrival" v-if="present"></v-text-field>  
              <v-text-field name="ctDateDeparture" type="date" label="Date départ" v-model="dateDeparture" v-if="!present"></v-text-field>  
              <h-group>
                <div class="mr-3">Préacheminement</div>
                <v-radio-group row v-model="aContainer.ctPreacheminement">
                  <v-radio label="Train" value="Train"></v-radio>
                  <v-radio label="Route" value="Route"></v-radio>
                </v-radio-group>
              </h-group>
              <v-text-field name="ctEtat" label="Etat" v-model="aContainer.ctEtat"></v-text-field>
              <v-checkbox label="Kit d'isolation" v-model="aContainer.ctKitIsolation"></v-checkbox>
            </v-group>

            <v-group class="pa-5" style="background-color: #F0F0F0;  width: 400px; align-items: stretch">
              <v-text-field name="ctExpedition" label="N° commande NW" v-model="aContainer.ctExpedition"></v-text-field>  
              <v-text-field name="ctSeal" label="Seal" v-model="aContainer.ctSeal"></v-text-field>  
              <v-text-field name="ctNestleBookingNumber" label="NW bookin number" v-model="aContainer.ctNestleBookingNumber"></v-text-field>  
            </v-group>

          </h-group>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('fermer')">Annuler</v-btn>
        <v-btn color="success" @click="boutonOkClickEvent()" :loading="loading" :disabled="!valid">ok</v-btn>
      </v-card-actions>
    </v-card>
    
  </v-dialog>
</template>

<script>
export default {

  data() {
    return {
      loading: false,
      snackErreur: false,
      snackErreurMessage: "",
      aContainer: new Object(),
      present: false,
      dateArrival: null,
      dateDeparture: null,
      creation: false,
      valid: false,
      preacheminement: "Train",
      nameRules: [
        v => !!v || 'Ce champ est nécessaire',
        v => this.checkNomConteneur(v) || 'Le nom doit comporter 4 lettres majuscules et 7 chiffres'
      ],
    }
  },

  props: {
    container: {
      type: Object
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    'dialog': {
      handler(newVal) {
        if (newVal) {
          // Initialisation
          this.aContainer = new Object();
          for(var key in this.container)
            this.aContainer[key] = this.container[key];
          const present = (this.aContainer.ctDateArrival != undefined) && (this.aContainer.ctDateArrival != null)
          this.dateArrival = present ? this.aContainer.ctDateArrival.substr(0,10) : new Date().toISOString().substr(0,10);
          this.dateDeparture = !present ? this.aContainer.ctDateDeparture.substr(0,10) : new Date().toISOString().substr(0,10);
          this.preacheminement = this.aContainer.ctPreacheminement == "Route" ? "Route" : "Train";
          this.present = present;
          this.creation = this.aContainer.ctContainer == ""
          
        }
      }
    }
  },

  methods: {
    afficherErreur(message) {
      this.loading = false;
      this.snackErreurMessage = message;
      this.snackErreur = true;
    },

    boutonOkClickEvent() {
      if (!this.creation) {
        this.modifier();
      }
      else {
        this.creer();
      }
    },

    mettreAJourDates() {
      if (this.present) {
        this.aContainer.ctDateArrival = new Date(this.dateArrival).toISOString();
        this.aContainer.ctDateDeparture = null;
      }
      else {
        this.aContainer.ctDateDeparture = new Date(this.dateDeparture).toISOString();
        this.aContainer.ctDateArrival = null;
      }
    },

    creer() {
      // Contrôle de doublon de container
      const filter = {"where": {"ctContainer": this.aContainer.ctContainer}}
      var url = "/api/ctContainers/?access_token=" + this.$user.id + "&filter=" + JSON.stringify(filter);
      this.loading = true;
      this.$axios.get(url)
        .then(function (response) {
          if (response.data.length >= 1) {
            this.afficherErreur("Le container " + this.aContainer.ctContainer + " existe déjà.")  
          }
          else {
            // Création du container dans la base de données
            this.mettreAJourDates();
            const url = "/api/ctContainers?access_token=" + this.$user.id;
            const body = this.aContainer;
            this.$axios.post(url, body)
              .then(function() {
                this.loading = false;
                this.$emit("containerCree", this.aContainer);
              }.bind(this))
              .catch(function (error) {
                this.afficherErreur(error.message + " " + error.response.statusText)
              }.bind(this))
          }
        }.bind(this))
        .catch(function(error) {
          this.afficherErreur(error.message + " " + error.response.statusText)
        }.bind(this))
    },

    modifier() {
      this.loading = true;
      this.mettreAJourDates();
      const url = "/api/ctContainers/" + this.aContainer.ctCtId + "?access_token=" + this.$user.id;
      const body = this.aContainer;
      this.$axios.put(url, body)
        .then(function() {
          this.loading = false;
          this.$emit("containerModifie", this.aContainer);
        }.bind(this))
        .catch(function (error) {
          this.afficherErreur(error.message + " " + error.response.statusText)
        }.bind(this))
    },

    /**
     * Contrôle du nom de conteneur
     * Le nom doit comporter 4 lettres suivies de 7 chiffres
     * @param {string} nom Nom du conteneur
     */
    checkNomConteneur(nom) {
      if (nom == undefined) {
        return false
      }
      if (nom.length == undefined) {
        return false
      }
      if (nom.length != 11) {
        return false
      }
      const p1 = nom.substr(0, 4)
      const r1 = /^[A-Z]+$/
      if (!r1.test(p1)) {
        return false
      }
      const p2 = nom.substr(4, 7)
      const r2 = /^[0-9]+$/
      return r2.test(p2)
    }
  }

}
</script>

<style>

</style>