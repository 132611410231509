<template>
  <v-container grid-list-xs>

    <!-- Sanckbar d'erreur -->
    <v-snackbar v-model="snackErreur" color="red">
      {{snackMessage}}
      <v-btn text color="black" @click.native="snackErreur = false">Close</v-btn>
    </v-snackbar>

    <!-- Popup de modification d'un container -->
    <ModifierContainer :dialog="dialogModifierContainer" @fermer="dialogModifierContainer = false"
      :container="container" @containerModifie="containerModifie"
      @containerCree="containerCree"
      >
    </ModifierContainer>


    <v-toolbar color="cyan" dark flat>

      <v-toolbar-title>Conteneurs et wagons</v-toolbar-title>
      
      <template v-slot:extension>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Recherche" class="mx-4" single-line hide-details></v-text-field>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab>
          Stock tampon conteneurs
        </v-tab>
        <v-tab>
          Conteneurs partis
        </v-tab>
        <v-tab>
          Stock tampon wagons
        </v-tab>
      </v-tabs>
      </template>
    </v-toolbar>
    

    <v-tabs-items v-model="tab">
      <v-tab-item class="mt-2">

        <!-- ---------------------------------------------- -->
        <!-- Stock tampon conteneurs : liste des conteneurs -->
        <!-- ---------------------------------------------- -->

        <!-- Boutons de commandes générales -->
        <h-group class="my-2">
          <v-btn color="lightgrey" class="mr-4" to="/histocontainer">
            <v-icon left>mdi-refresh</v-icon>
            Historique des mouvements</v-btn>

          <v-spacer></v-spacer> 

          <div class="mr-4">{{stockTamponContainersSearch.length}} container{{stockTamponContainersSearch.length > 1 ? 's' : ''}}</div>

          <v-btn color="lightgrey" class="mr-4" @click="rafraichirStockTampon()">
            <v-icon left>mdi-refresh</v-icon>
            Rafraîchir</v-btn>

          <v-btn color="lightgrey" class="mr-4" @click="exporter(stockTamponContainersSearch, 'export-stock-tampons-containers.csv')">
            <v-icon left>mdi-export</v-icon>
            Exporter</v-btn>
          <v-btn color="info" @click="creerContainer()" v-if="$USER_CONTAINERADMIN">
            <v-icon left>mdi-plus</v-icon>
            Créer un conteneur</v-btn>
        </h-group>
        <v-data-table dense :loading="loading" :headers="headersStockTamponContainers" :items="stockTamponContainers" item-key="ctCtId"
            class="elevation-1" :search="search" :items-per-page="-1" style="height: 650px; overflow: auto;"
            @current-items="getStockTamponContainersSearch"
            >
          
          <!-- colonne index -->
          <template #item.index="{ index }">
            {{ index + 1 }}
          </template>
          
          <!-- colonne durée de présence -->
          <template v-slot:item.duree="{ item }">
            <v-chip :color="getColor(item.ctDateArrival)" dark class="ma-1">{{ computeDuree(item.duree) }}</v-chip>
          </template>
          
          <!-- colonne kit isolation -->
          <template v-slot:item.ctKitIsolation="{ item }">
            <div>{{item.ctKitIsolation ? 'Oui' : 'Non'}}</div>
          </template>
          
          <!-- colonne date d'arrivée -->
          <template v-slot:item.ctDateArrival="{ item }">
            {{ computeDateArrival(item.ctDateArrival) }}
          </template>

          <!-- colonne boutons d'actions sur le container -->
          <template v-slot:item.actions="{ item }">

            <!-- bouton historique-->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="mr-2" color="info" @click="historique(item)" v-bind="attrs" v-on="on">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Historique des arrivées et départs</span>
            </v-tooltip>
            
            <!-- bouton modifier-->        
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="success" @click="modifierContainer(item)" v-if="$USER_CONTAINERADMIN" v-bind="attrs" v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Modifier le conteneur</span>
            </v-tooltip>

          </template>
        </v-data-table>
      </v-tab-item>

      <v-tab-item class="mt-2">
        <!-- ---------------------------------------------- -->
        <!-- conteneurs partis -->
        <!-- ---------------------------------------------- -->

        <!-- Boutons de commandes générales -->
        <h-group class="my-2">
          <v-spacer></v-spacer> 
          <div class="mr-4">{{containersPartis.length}} conteneur{{containersPartisSearch.length > 1 ? 's' : ''}}</div>

          <v-text-field name="dateDebut" class="mr-4" style="max-width: 200px" type="date" label="Date de début" v-model="dateDebut"></v-text-field>  

          <v-text-field name="dateFin" class="mr-4" style="max-width: 200px" type="date" label="Date de fin" v-model="dateFin"></v-text-field>  
          
          <div class="filtre">
            <v-text-field name="filtreNumero" class="mr-4" type="text" label="Numéro" v-model="filtreNumero"></v-text-field>  
          </div>
          <div class="filtre">
            <v-text-field name="filtreScac" class="mr-4" type="text" label="Scac" v-model="filtreScac"></v-text-field>  
          </div>
          <div class="filtre">
            <v-text-field name="filtreStatut" class="mr-4" type="text" label="Statut" v-model="filtreStatut"></v-text-field>  
          </div>
          <div class="filtre">
            <v-text-field class="mr-4" type="text" label="Cde NW" v-model="filtreCdeNw"></v-text-field>  
          </div>
          <div class="filtre">
            <v-text-field class="mr-4" type="text" label="Seal" v-model="filtreSeal"></v-text-field>  
          </div>

          <v-btn color="lightgrey" class="mr-4" @click="rafraichirContainersPartis()">
            <v-icon left>mdi-refresh</v-icon>
            Rafraîchir</v-btn>
          <v-btn color="lightgrey" class="mr-4" @click="exporter(containersPartis, 'export-containers-partis.csv')">
            <v-icon left>mdi-export</v-icon>
            Exporter</v-btn>
        </h-group>
        <v-data-table dense :loading="loading" :headers="headersContainersPartis" :items="containersPartis" item-key="ctCtId"
            class="elevation-1" :search="search" :items-per-page="500"
            @current-items="getContainersPartisSearch"  style="height: 600px; overflow: auto;"
            >
          
          <!-- colonne index -->
          <template #item.index="{ index }">
            {{ index + 1 }}
          </template>
          
          <!-- colonne kit isolation -->
          <template v-slot:item.ctKitIsolation="{ item }">
            <div>{{item.ctKitIsolation ? 'Oui' : 'Non'}}</div>
          </template>
          
          <!-- colonne date de départ -->
          <template v-slot:item.ctDateDeparture="{ item }">
            {{ computeDateArrival(item.ctDateDeparture) }}
          </template>

          <!-- colonne boutons d'actions sur le container -->
          <template v-slot:item.actions="{ item }">

            <!-- bouton historique-->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="mr-2" color="info" @click="historique(item)" v-bind="attrs" v-on="on">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Historique des arrivées et départs</span>
            </v-tooltip>
            
          </template>
        </v-data-table>

      </v-tab-item>

      <v-tab-item class="mt-2">
        <h3 class="mt-5">En cours de développement ...</h3>
      </v-tab-item>

    </v-tabs-items>

  </v-container>
</template>

<script>
import axios from 'axios';
import ModifierContainer from './popup/ModifierContainer.vue'

export default {

  components: {ModifierContainer},

  data() {
    return {

      stockTamponContainers: [],
      stockTamponContainersSearch: [],

      containersPartis: [],
      containersPartisSearch: [],

      stockTamponWagons: [],
      stockTamponWagonsSearch: [],

      container: {},
      loading: false,
      snackErreur: false,
      snackMessage: "",
      dialogModifierContainer: false,
      parameters: undefined,
      containerDureePresenceSeuil1: 3,
      containerDureePresenceSeuil2: 4,
      tab: null,
      dateDebut: undefined,
      dateFin: undefined,
      filtreNumero: '',
      filtreScac: '',
      filtreSeal: '',
      filtreCdeNw: '',
      filtreStatut: '',

      headersStockTamponContainers: [
         { text: 'Index', value: 'index' },
         { text: 'Numéro', value: 'ctContainer' },
         { text: 'Scac', value: 'ctScac' },
         { text: 'Statut', value: 'ctStatut' },
         { text: 'Cde NW', value: 'ctExpedition' },
         { text: 'Seal', value: 'ctSeal' },
         { text: 'NW booking number', value: 'ctNestleBookingNumber' },
         { text: 'Kit ISO', value: 'ctKitIsolation' },
         { text: 'Etat', value: 'ctEtat' },
         { text: 'Préach.', value: 'ctPreacheminement' },
         { text: 'Arrivée', value: 'ctDateArrival' },
         { text: 'Durée présence', value: 'duree' },
         { text: '', value: 'actions'}
      ],

      headersContainersPartis: [
         { text: 'Index', value: 'index' },
         { text: 'Numéro', value: 'ctContainer' },
         { text: 'Scac', value: 'ctScac' },
         { text: 'Statut', value: 'ctStatut' },
         { text: 'Cde NW', value: 'ctExpedition' },
         { text: 'Seal', value: 'ctSeal' },
         { text: 'NW booking number', value: 'ctNestleBookingNumber' },
         { text: 'Kit ISO', value: 'ctKitIsolation' },
         { text: 'Etat', value: 'ctEtat' },
         { text: 'Préach.', value: 'ctPreacheminement' },
         { text: 'Départ', value: 'ctDateDeparture' },
         { text: '', value: 'actions'}
      ],

      search: "",
    }
  },

  watch: {
    tab: function(newValue) {
      console.log("tab", newValue)
      switch(newValue) {
        case 0:
          // Mise à jour du stock tampon
          if (this.stockTamponContainers.length == 0) {
            this.rafraichirStockTampon();
          }
          break;
        case 1:
          // Mise à jour du stock tampon
          if (this.containersPartis.length == 0) {
            this.rafraichirContainersPartis();
          }
          break;
      }
    } 
  },

  methods: {

    async rafraichirParametres() {
      if (this.parameters == undefined) {
        var filter = {"where": {"prName": {"like": "wd%"}}}
        await axios.get("/api/prParameters?filter=" + encodeURIComponent(JSON.stringify(filter)) + "&access_token=" + this.$user.id)
        .then(function (response) {
          this.parameters = response.data
          this.containerDureePresenceSeuil1 = parseInt(response.data.find(p => p.prName == "WdContainerDureePresenceSeuil1").prValue)
          this.containerDureePresenceSeuil2 = parseInt(response.data.find(p => p.prName == "WdContainerDureePresenceSeuil2").prValue)
          console.log("this.containerDureePresenceSeuil1", this.containerDureePresenceSeuil1, "this.containerDureePresenceSeuil2", this.containerDureePresenceSeuil2)
        }.bind(this))
        .catch(error => {
          if (error.response.status == 401) {
            // Erreur "accès refusé", redirection vers la page de connexion
            this.$router.push('/login?to=/containers')
          }
          else {
            this.afficherErreur(error.message + " : " + error.response.statusText)
            this.stockTamponContainers = [];
          }
        });
      }
    },

    async rafraichirStockTampon() {
      this.loading = true;
      await this.rafraichirParametres();
      var filter = {"where": {"ctDateDeparture": null}}
      var url = "/api/ctContainers?filter=" + JSON.stringify(filter) + "&access_token=" + this.$user.id;
      axios.get(url)
        .then(function (response) {
          this.stockTamponContainers = response.data;
          
          // Calcul des durées de présence
          this.stockTamponContainers.forEach(container => {
            if ((container.ctPreacheminement == null) || (container.ctPreacheminement == undefined) && (container.ctPreacheminement == "")) {
              container.ctPreacheminement = "Train"
            }
            if (container.ctDateArrival == null) {
              container.duree = 0;
            }
            else {
              container.duree = this.calculerDuree(container.ctDateArrival);
            }
          })

          // Tri par durée de présence décroissante
          this.stockTamponContainers.sort(function (a, b) { return b.duree - a.duree;});

          this.loading = false;
        }.bind(this))
        .catch(error => {
          if (error.response.status == 401) {
            // Erreur "accès refusé", redirection vers la page de connexion
            this.$router.push('/login?to=/containers')
          }
          else {
            this.afficherErreur(error.message + " : " + error.response.statusText)
            this.stockTamponContainers = [];
          }
        });
    },

    async rafraichirContainersPartis() {
      this.loading = true;
      await this.rafraichirParametres();
      var and = [{"ctDateArrival": null}, {"ctDateDeparture":{"gte": new Date(this.dateDebut)}}, {"ctDateDeparture":{"lte": new Date(this.dateFin)}}]
      if (this.filtreNumero != "") {
        and.push({"ctContainer": {"like": '%' + this.filtreNumero + '%'}})
      }
      if (this.filtreScac != "") {
        and.push({"ctScac": {"like": '%' + this.filtreScac + '%'}})
      }
      if (this.filtreStatut != "") {
        and.push({"ctStatut": {"like": '%' + this.filtreStatut + '%'}})
      }
      if (this.filtreSeal != "") {
        and.push({"ctSeal": {"like": '%' + this.filtreSeal + '%'}})
      }
      if (this.filtreCdeNw != "") {
        and.push({"ctExpedition": {"like": '%' + this.filtreCdeNw + '%'}})
      }
      //var filter = {"where": {"ctDateArrival": null}}
      var filter = {"where": {"and": and}}
      console.log("filter", filter)
      var url = "/api/ctContainers?filter=" + encodeURI(JSON.stringify(filter)) + "&access_token=" + this.$user.id;
      axios.get(url)
        .then(function (response) {
          this.containersPartis = response.data;

          // Tri par date de départ décroissante
          this.stockTamponContainers.sort(function (a, b) { return b.ctDateDeparture - a.ctDateDeparture;});

          this.loading = false;
        }.bind(this))
        .catch(error => {
          if (error.response.status == 401) {
            // Erreur "accès refusé", redirection vers la page de connexion
            this.$router.push('/login?to=/containers')
          }
          else {
            this.afficherErreur(error.message + " : " + error.response.statusText)
            this.stockTamponContainers = [];
          }
        });
    },

    afficherErreur(message) {
      this.snackMessage = message;
      this.snackErreur = true
      this.loading = false;
    },

    getStockTamponContainersSearch(liste) {
      this.stockTamponContainersSearch = liste;
    },

    getContainersPartisSearch(liste) {
      this.containersPartisSearch = liste;
    },

    getStockTamponWagonSearch(liste) {
      this.stockTamponWagonSearch = liste;
    },

    calculerDuree(sdate) {
      const date = new Date(sdate);
      const now = Date.now();
      const dureeMs = now - date.getTime();
      const dureeJ = dureeMs / 1000 / 60 / 60 / 24;
      return Math.floor(dureeJ)
    },

    computeDuree(duree) {
      return duree.toFixed(0) + (duree >= 2 ? " jours" : " jour");
    },

    computeDateArrival(sdate) {
      return ((sdate != null) && (sdate != undefined) && (sdate.substr != undefined)) ? sdate.substr(0,10) : "";
    },

    getColor (sdate) {
      if ((sdate == undefined) || (sdate == null))
        return 'grey';
      const dureeJ = this.calculerDuree(sdate)
      if (dureeJ >= this.containerDureePresenceSeuil2) return 'red'
      else if (dureeJ >= this.containerDureePresenceSeuil1) return 'orange'
      else return 'green'
    },

    historique(item) {
      this.$router.push('/histocontainer/' + item.ctContainer);
    },

    exporter(tableau, fichier) {
      this.$exporterCsv(tableau, fichier)
    },

    modifierContainer(container) {
      this.container = container;
      this.dialogModifierContainer = true;
    },

    creerContainer() {
      this.container = {
        "ctContainer": "",
        "ctDateArrival": new Date().toISOString(),
        "ctDateDeparture": null,
        "ctPoids": 0,
        "ctTare": 0,
        "ctScac": "",
        "ctStatut": "",
        "ctType": "",
        "ctEtat": "",
        "ctKitIsolation": false,
        "ctPreacheminement": "Train"
      };
        this.dialogModifierContainer = true;
    },

    containerModifie(container) {
      for(var key in container)
        this.container[key] = container[key];
      this.container.duree = this.container.ctDateArrival != null ? this.calculerDuree(this.container.ctDateArrival) : 0;
      this.dialogModifierContainer = false;
    },

    containerCree(container) {
      container.duree = container.ctDateArrival != null ? this.calculerDuree(container.ctDateArrival) : 0;
      this.containers.push(container);
      this.containersFiltres.push(container);
      this.dialogModifierContainer = false;
    }
  },

  mounted() {
    this.dateDebut = new Date(Date.now() - 86400 * 1000 * 366 * 1).toISOString().substr(0,10)
    this.dateFin = new Date(Date.now() + 86400 * 1000 * 1).toISOString().substr(0,10)
    //this.rafraichir();
  }
};
</script>

<style>
.filtre {
  width: 160px;
}
</style>